export enum DimensionsEnum {
  AgeGroup = 'ageGroup',
  Gender = 'gender',
  ZipCode = 'zipCode',
  IrisCode = 'irisCode',
  Revenue = 'revenue',
  Department = 'department',
  District = 'district',
  FamilySquare = 'familySquare',
}
